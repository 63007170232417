//* Vue CSR (Client Side) i18n Translations Plugin

const { createI18n } = require('vue-i18n');
const enTranslations = require('./translations-en.json');
const esTranslations = require('./translations-es.json');

const i18nEN = createI18n({
  locale: 'en',
  fallBackLocale: 'en',
  globalInjection: true,
  messages: {
    en: enTranslations,
  },
});

const i18nES = createI18n({
  locale: 'es',
  fallBackLocale: 'es',
  globalInjection: true,
  messages: {
    es: esTranslations,
  },
});

module.exports = { en: i18nEN, es: i18nES };
