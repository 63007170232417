/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
// @ts-nocheck

import CONFIG_ENUMS from './connatix.video.configEnums';
import BASE_SETTINGS from './connatix.video.baseSettings';
import videoUtils from '../utils/video-utils';
import videoAllowed from '../settings/allowed-video-types';
import Requester from '../../app/api/Requester';

class VideoPlayer {
  constructor(cms, data, settings) {
    this.cid = cms.cid;
    this.instanceId = cms.instanceId;
    this.renderId = cms.renderId;
    this.playerId = cms.playerId;
    this.videoId = cms.videoId;
    this.videoListIds = cms.videoListIds;
    this.escenicId = data.escenicId;
    this.assetType = data.assetType;
    this.videoType = data.videoType;
    this.settings = settings;
    this.playerApi = undefined;
    this.videoDetails = {};
    this.metadataList = [];
    this.newVideoClick = '';
    this.updateStoreEvent = new CustomEvent('updateStoreEvent');
    this.isPlaying = false;
    this.autoplaySubscriberEnabled = false;
    // New flag to track if the volume has been manually adjusted
    this.hasVolumeAdjusted = false;
  }

  //* Render State
  renderSuccess(playerApi) {
    this.setCnxObjects(playerApi);
    this.restrictGraphicContent();
    this.replacePlayButton();
    if (videoUtils.isDev) {
      videoUtils.consoleLog('VIDEO_PLAYER_CNX', 'Success');
    }
  }
  renderFail(error) {
    if (videoUtils.isDev) {
      videoUtils.consoleLog('VIDEO_PLAYER_CNX', 'Fail', error);
    }
  }

  //* Utils
  isMobile() {
    return navigator.userAgent.indexOf('Mobile') !== -1
      || navigator.userAgent.indexOf('iPhone') !== -1
      || navigator.userAgent.indexOf('Android') !== -1
      || navigator.userAgent.indexOf('Windows Phone') !== -1;
  }
  isSubscriber() {
    const hasDigitalData = window.hasOwnProperty('digitalData');
    const subscriptionInfo = {
      isLoggedIn: hasDigitalData ? window.digitalData.user.logged_in : false,
      isSubscribed: hasDigitalData ? window.digitalData.user.subscriber === 'true' : false,
    };
    return subscriptionInfo.isLoggedIn && subscriptionInfo.isSubscribed;
  }
  isGraphicContent() {
    const keywords  = this.videoDetails && this.videoDetails.keywords ? this.videoDetails.keywords : '';
    return keywords.includes('norec') || keywords.includes('graphic');
  }
  isHomepage() {
    return window.pageInfo['marketInfo.pagelevel'] === 'homepage';
  }
  isSportsArticle() {
    const stnDisabled = this.settings.stnKill || this.settings.stnDisableSticky;
    return this.settings.sportsCoincidence && stnDisabled;
  }
  getVideoPlayerContainer() {
    return document.querySelector(`#${this.renderId}`).parentElement;
  }
  getPlayingState() {
    return this.isPlaying;
  }
  getStickyPlayerId() {
    return `#sticky-player-video-${this.instanceId}`;
  }
  getStickyPlayerContainer() {
    return document.querySelector(`#sticky-player-${this.instanceId}`);
  }
  addContainerClass(className) {
    const playerContainer = this.getVideoPlayerContainer();
    playerContainer.classList.add(className);
  }

  //* Methods
  enableGalleryLayout(style, orientation) {
    if (orientation === 'Landscape' && !this.isMobile()) {
      this.addContainerClass('wps-gallery');
    } else {
      this.addContainerClass('wps-playlist');
    }
    return {
      nextVideoMode: CONFIG_ENUMS.NextVideoModeEnum.Next,
      gallery: {
        style: CONFIG_ENUMS.GalleryStyleModeEnum[style],
        orientation: CONFIG_ENUMS.GalleryOrientationEnum[orientation],
      },
    };
  }
  updateVideoDetails() {
    if (typeof this.playerApi !== 'undefined') {
      this.videoDetails = this.playerApi.getVideoDetails();
      this.metadataList = this.playerApi.getAllMetadata;
      this.newVideoClick = this.playerApi.nextVideo;
    }
  }
  restrictGraphicContent() {
    if (this.isGraphicContent()) {
      this.playerApi.pause();
      videoUtils.devLog('VIDEO_AUTOPLAY_GRAPHIC_CONTENT', 'OFF');
    }
  }
  displayUpNext(display) {
    const upNextMode = this.settings.nextVideoMode;
    const upNextOn = CONFIG_ENUMS.NextVideoModeEnum.Next;

    if (upNextMode === upNextOn) {
      const videoPlayer = this.getVideoPlayerContainer();
      const upNext = videoPlayer.querySelector('.cnx-up-next-container');
      upNext.style.display = display;
    }
  }
  displayShareIcon(visibility) {
    const videoPlayer = this.getVideoPlayerContainer();
    const upNext = videoPlayer.querySelector('.wps-share-button-overlay');
    upNext.style.visibility = visibility;
  }
  replacePlayButton() {
    const videoPlayer = this.getVideoPlayerContainer();
    const playIcon = videoPlayer.querySelector('.cnx-button-big-play .cnx-play-icon');
    const newIcon = '<img src="/wps/source/images/video/video-play-icon.svg"></img>';
    if(playIcon) playIcon.innerHTML = newIcon;
  }
  changePlayingState(isPlaying) {
    this.isPlaying = isPlaying;
  }

  //* Set up
  setCnxObjects(playerApi) {
    this.playerApi = playerApi;
    this.updateVideoDetails();
  }
  setAutoplaySettings() {
    const autoplayOn = {
      playbackMode: CONFIG_ENUMS.PlaybackModeEnum.ScrollToPlay,
      defaultSoundMode: CONFIG_ENUMS.DefaultSoundModeEnum.Off,
    };
    const autoplayOff = {
      playbackMode: CONFIG_ENUMS.PlaybackModeEnum.ClickToPlay,
      defaultSoundMode: CONFIG_ENUMS.DefaultSoundModeEnum.On,
    };
    const canAutoplay = videoAllowed('autoplay', this.videoType);
    let autoplaySettings = autoplayOff;

    if (canAutoplay && this.settings.autoplay) {
      autoplaySettings = autoplayOn;
      videoUtils.devLog('VIDEO_AUTOPLAY', 'ON');

      if (this.isMobile() && !this.settings.autoplayMobile) {
        autoplaySettings = autoplayOff;
        videoUtils.devLog('VIDEO_AUTOPLAY_MOBILE', 'OFF');
      }
      if (this.isSubscriber()) {
        if (this.settings.autoplaySubscriber) {
          autoplaySettings.defaultSoundMode = CONFIG_ENUMS.DefaultSoundModeEnum.Off; // Muted autoplay
          this.autoplaySubscriberEnabled = true;
        } else {
          autoplaySettings = autoplayOff;
          videoUtils.devLog('VIDEO_AUTOPLAY_SUBS', 'OFF');
        }
      }
     
      if (this.isSportsArticle()) {
        if (!this.settings.sportsOverride) { 
          autoplaySettings = autoplayOn; 
        } else {
          autoplaySettings = autoplayOff;
          videoUtils.devLog('VIDEO_AUTOPLAY_SPORTS_ARTICLE', 'OFF');
        }
      }
    }
    return autoplaySettings;
  }
  async setFloatSettings() {
    const floatOn = {
      mode: CONFIG_ENUMS.FloatingModeEnum.AfterInView,
      closeButton: CONFIG_ENUMS.CloseButtonModeEnum.AlwaysOn,
      device: CONFIG_ENUMS.FloatingDevicesEnum.Desktop,
      fixedPosition: CONFIG_ENUMS.FloatingFixedPositionModeEnum.Page,
      //floatingPageElement: this.getStickyPlayerId(),
      floatingAppendLocation: CONFIG_ENUMS.FloatingAppendLocationEnum.BottomRight,
      floatingGutterX: 0,
      floatingGutterY: 120,
      floatingWidth: 350,
      hasFloatingTransition: true,
    };
    const floatOff = {
      mode: CONFIG_ENUMS.FloatingModeEnum.Off,
    };
    const floatOnMobile ={
      device : CONFIG_ENUMS.FloatingDevicesEnum.Smartphone,
      fixedPosition : CONFIG_ENUMS.FloatingFixedPositionModeEnum.Page,
      floatingWidth: 350,
      floatingGutterX: 0,
      floatingGutterY: 70,
    }
    const canFloat = videoAllowed('float', this.videoType);
    let floatSettings = floatOff;

    if (canFloat && this.settings.float) {
      floatSettings = floatOn;
      videoUtils.devLog('VIDEO_FLOAT', 'ON');

      if (this.isMobile()) {
        floatSettings = { ...floatOn, ...floatOnMobile };
        videoUtils.devLog('VIDEO_FLOAT_MOBILE', 'ON');
      }

      if (this.isSportsArticle()) {
        if (!this.settings.sportsOverride) { 
          floatSettings = floatOn;
        } else {
          floatSettings = floatOff;
          videoUtils.devLog('VIDEO_FLOAT_SPORTS_ARTICLE', 'OFF');
        }
      }
    }

    return {
      customization: {
        ...BASE_SETTINGS(await this.verticalVideo()).customization,
        floating: floatSettings,
      },
    };
  }
  setFloatHomepageSettings() {
    const floatOn = {
      mode: CONFIG_ENUMS.FloatingModeEnum.OnPageLoad,
      closeButton: CONFIG_ENUMS.CloseButtonModeEnum.AlwaysOn,
      device: CONFIG_ENUMS.FloatingDevicesEnum.Desktop,
      fixedPosition: CONFIG_ENUMS.FloatingFixedPositionModeEnum.Page,
      scrollPosition: CONFIG_ENUMS.FloatingScrollPositionEnum.BottomRight,
      floatingGutterX: 0,
      floatingGutterY: 120,
      floatingWidth: 400,
      hasFloatingTransition: true,
    };
    const floatOnMobile = {
      device: CONFIG_ENUMS.FloatingDevicesEnum.Smartphone,
    };
    const floatOff = {
      mode: CONFIG_ENUMS.FloatingModeEnum.Off,
    };

    const autoplayOn = {
      playbackMode: CONFIG_ENUMS.PlaybackModeEnum.ScrollToPlay,
      defaultSoundMode: CONFIG_ENUMS.DefaultSoundModeEnum.Off,
    };
    const autoplayOff = {
      playbackMode: CONFIG_ENUMS.PlaybackModeEnum.ClickToPlay,
      defaultSoundMode: CONFIG_ENUMS.DefaultSoundModeEnum.On,
    };

    let floatSettings = floatOff;
    let autoplaySettings = autoplayOff;
    const logMessage = { message: 'VIDEO_AUTOPLAY_FLOAT_HOMEPAGE', status: 'OFF' };

    if (this.isHomepage() && (!this.isSubscriber() || this.settings.floatHomepageSubscriber) && this.settings.floatHomepage) {
      floatSettings = floatOn;
      autoplaySettings = autoplayOn;
      logMessage.status = 'ON';
      if (this.isMobile()) {
        logMessage.message = logMessage.message.concat('_MOBILE');
        if (this.settings.floatHomepageMobile) {
          floatSettings = { ...floatOn, ...floatOnMobile };
        } else {
          floatSettings = floatOff;
          autoplaySettings = autoplayOff;
          logMessage.status = 'OFF';
        }
      }
    }

    videoUtils.devLog(logMessage.message, logMessage.status);

    return {
      ...autoplaySettings,
      customization: {
        floating: floatSettings,
      },
    };
}
  setUpNextSettings() {
    const upNextOn = {
      nextVideoMode: CONFIG_ENUMS.NextVideoModeEnum.Next,
    };
    const upNextOff = {
      nextVideoMode: CONFIG_ENUMS.NextVideoModeEnum.Loop,
    };
    const canUpNext = videoAllowed('upNext', this.videoType);
    let upNextSettings = upNextOff;

    if (canUpNext && this.settings.upNext) {
      upNextSettings = upNextOn;
      videoUtils.devLog('VIDEO_UP_NEXT', 'ON');
    }

    return upNextSettings;
  }

  //FE-48 --Returns if video is vertical or not
  async verticalVideo() {
      const url = window.location.href;
      const pattern = /article(\d+)\.html/;
      const match = pattern.exec(url);
      let extractedID = null;
      if(match){
          extractedID = match[1];
      }
      if(extractedID) {
        const isvertical = await Requester.getVerticalVideo(extractedID);
        return isvertical;
       } else {
          return false;
      }
  }

  async setupVideoConfig() {   
    const config = {
      settings: {
        ...this.setAutoplaySettings(),
        ...(await this.setFloatSettings()),
        ...this.setUpNextSettings(),
      },
    };

    switch (this.assetType) {
      case 'video': {     
        if(this.videoType === 'connatix-embed'){
          config.playerId = '251ccae3-131e-437c-87ca-b1c446d421c9';
        } else if (this.videoType !== 'video-gallery') {
//WPS-4991 changes - for lead and inline showing diff players for sub and non sub.
          if(this.videoType === 'lead-asset' || this.videoType === 'inline'){
            if(this.isSubscriber()){              
              config.playerId = (typeof this.playerId["regularSub-"+this.videoType] !== 'undefined') ? this.playerId["regularSub-"+this.videoType] : this.playerId.regular;
            } 
            else{              
              config.playerId = (typeof this.playerId["regularNonsub-"+this.videoType] !== 'undefined') ? this.playerId["regularNonsub-"+this.videoType] : this.playerId.regular;
            } 
          }
         
          else{
            config.playerId = this.playerId.regular;
          }

          config.mediaId = this.videoId;
        } else {
          config.playerId = this.playerId.gallery;
          config.useMediaIdListAsPlaylist = true;
          config.settings = { ...config.settings, ...this.setFloatHomepageSettings(), ...this.enableGalleryLayout('Visual', 'Landscape') };
        }
        break;
      }
      case 'playlist': {
        config.playerId = this.playerId.regular;
        if (!videoAllowed('playlist', this.videoType)) {
          config.playlistId = this.videoId;
        } else {
          config.playlistId = this.videoId;
          if (videoAllowed('story', this.videoType)) this.addContainerClass('wps-story-playlist');
          config.settings = { ...config.settings, ...this.enableGalleryLayout('Visual', 'Portrait') };
        }
        break;
      }
      default: {
        this.renderFail('Asset type is not defined');
        break;
      }
    }

    this.settings = config.settings;
    return config;
  }

  //* Custom Events
  onCloseStickyPlayer() {
    const stickyPlayer = this.getStickyPlayerContainer();
    stickyPlayer.classList.remove('is-sticky');
    this.playerApi.disableFloatingMode();
  }

  //* Events
  onNewVideo() {
    this.updateVideoDetails();
    const playerContainer = this.getVideoPlayerContainer();
    playerContainer.dispatchEvent(this.updateStoreEvent);
  }
  onFloatingEnterStart() {
    const stickyPlayer = this.getStickyPlayerContainer();
    stickyPlayer.classList.add('is-sticky');
    this.displayUpNext('none');
    this.displayShareIcon('hidden');
  }
  onFloatingExitStart() {
    const stickyPlayer = this.getStickyPlayerContainer();
    stickyPlayer.classList.remove('is-sticky');
  }
  onFloatingExitStop() {
    this.displayUpNext('block');
    this.displayShareIcon('visible');
  }
  onPlay() {
    this.changePlayingState(true);
    
    if (this.isSubscriber()) {
      if (this.autoplaySubscriberEnabled && !this.hasVolumeAdjusted) {
        this.playerApi.setVolume(0); // Keep muted if autoplaySubscriber is true
        this.hasVolumeAdjusted = true; 
      } else if (!this.hasVolumeAdjusted){
        this.playerApi.setVolume(0.3); // Set to 30% for manual play if autoplaySubscriber is false
        this.hasVolumeAdjusted = true; 
      }
    }
  }
    
  onPause() {
    this.changePlayingState(false);
  }

  async render(videoPlayer) {
    return new Promise((resolve, reject) => {
      const { cid } = videoPlayer;
      (async () => {
      const config = await videoPlayer.setupVideoConfig();

      if (typeof cnxel !== 'undefined' && !!cid) {
        (new Image()).src = `https://cd.thecontentserver.com/tr/si?token=45fdac0d-132d-416d-9337-6dc76d9a7ac7&cid=${cid}`;
        cnxel.cmd.push(function () {
          cnxel(config)
            .render(videoPlayer.renderId, (renderError, playerApi) => {
              if (renderError) {
                videoPlayer.renderFail(renderError.message);
              } else {
                videoPlayer.renderSuccess(playerApi);

                //* Player API events
                playerApi.on('play', () => { videoPlayer.onPlay(); });
                playerApi.on('pause', () => { videoPlayer.onPause(); });
                playerApi.on('newVideo', () => { videoPlayer.onNewVideo(); });
                playerApi.on('floatingEnterStart', () => { videoPlayer.onFloatingEnterStart(); });
                playerApi.on('floatingExitStart', () => { videoPlayer.onFloatingExitStart(); });
                playerApi.on('floatingExitStop', () => { videoPlayer.onFloatingExitStop(); });
                
                //Add vertical-video class based on the result of verticalVideo()
                const videoWraps = document.querySelectorAll('.wps-player-wrap');
                
                // Iterate over all elements with the class 'wps-player-wrap'
                videoWraps.forEach(videoWrap => {
                    const container = videoPlayer.getVideoPlayerContainer();
                    videoPlayer.verticalVideo().then(isVertical => {
                        if (isVertical) {
                            videoWrap.classList.add('vertical-video');
                            container.classList.add('vertical-video');
                        }
                    });
                });

                
                resolve('Success');
              }
            });
        });
      } else {
        const errMsg = 'The cnx object does not exist or missing cid';
        videoPlayer.renderFail(errMsg);
        reject(errMsg);
      }
    })();
    });
  }
}

export default VideoPlayer;
