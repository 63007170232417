/* eslint-disable max-len */
const VIDEO_TYPES = Object.freeze({
  lead: 'lead-asset',
  inline: 'inline',
  embed: 'video-embed',
  ampEmbed: 'video-embed-amp',
  topicsLead: 'topics-lead-asset',
  sectionLead: 'section-lead-asset',
  videoDetail: 'video-detail',
  bigNews: 'big-news',
  videoGallery: 'video-gallery',
  inDepth: 'in-depth',
  immersive: 'immersive',
});

const allowedByCategory = {
  //* Types
  playlist: [VIDEO_TYPES.lead, VIDEO_TYPES.inline, VIDEO_TYPES.sectionLead],
  story: [VIDEO_TYPES.lead, VIDEO_TYPES.inline],

  //* Settings
  autoplay: [VIDEO_TYPES.lead, VIDEO_TYPES.videoDetail],
  float: [VIDEO_TYPES.lead, VIDEO_TYPES.videoDetail],
  upNext: [VIDEO_TYPES.lead, VIDEO_TYPES.inline, VIDEO_TYPES.videoDetail],
  prerollAd: Object.values(VIDEO_TYPES),

  //* Components
  titleOverlay: Object.values(VIDEO_TYPES),
  description: [VIDEO_TYPES.lead, VIDEO_TYPES.inline, VIDEO_TYPES.immersive],
  shareButton: Object.values((({ videoGallery, ...videoTypesForShareButton }) => videoTypesForShareButton)(VIDEO_TYPES)),
};


export default function videoAllowed(category, videoType) {
  return allowedByCategory[category].includes(videoType);
}

