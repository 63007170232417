<template>
  <component :is="selector()"></component>
</template>

<script>
  import { useVideoStore } from './store/video-component/video-store';
  import { mapStores } from 'pinia';
  
  import PlayerComponent from './components/player-component/player-component.vue';
  import VideoDetailComponent from './components/video-detail/video-detail-component.vue'

  export default {
    name: 'Video Application',
    components:{
      player: PlayerComponent,
      videoDetail: VideoDetailComponent,
    },
    props: {
      //* Video CMS
      cid: String,
      playerId: String,
      videoId: String,
      
      //* Video Data
      assetType: String,
      videoType: String,
      escenicId: String,
      url: String,
      title: String,
      thumbnail: String,
      channel: String,
      sectionName: String,
      sectionUrl: String,
      photographer: String,
      description: String,
      publishedDate: String,
      modifiedDate: String,

      //* Video Settings
      autoplay: String,
      autoplayMobile: String,
      autoplaySubscriber: String,
      sportsOverride: String,
      float: String,
      floatHomepage: String,
      floatHomepageMobile: String,
      floatHomepageSubscriber: String,
      upNext: String,
      isTaboolaMarkup: String,

      //* Video Settings (Sports Coincidence)
      sportsCoincidence: { type: String, default: 'false' },
      stnKill: { type: String, default: 'false' },
      stnDisableSticky: { type: String, default: 'false' },

      //* Section Config
      lang: String,
      dateFormat: String,
      pubTimeZone: String,
      isPaywall5050Service: String,

      //Tags
      secTag: String,
      topicTag: String,
    },
    computed: {
      ...mapStores(useVideoStore),
    },
    methods: {
      selector() {
        // @ts-ignore
        switch (this.videoStore?.videoData?.videoType) {
          case 'video-detail':
            return 'video-detail';
            break;
          default:
            return 'player';
            break;
        }
      },
    },
    beforeMount() {
      //* Video Store State
      const videoCms = {
        instanceId: null,
        renderId: null,
        cid: this.cid,
        playerId: JSON.parse(this.playerId),
        videoId: this.videoType === 'video-gallery' ? this.videoId.split(',')[0] : this.videoId,
        videoListIds: this.videoType === 'video-gallery' ? this.videoId.split(',') : null,
      };
      const videoData = {
        assetType: this.assetType,
        videoType: this.videoType,
        escenicId: this.escenicId,
        url: this.url,
        title: this.title,
        thumbnail: this.thumbnail,
        channel: this.channel,
        sectionName: this.sectionName,
        sectionUrl: this.sectionUrl,
        photographer: this.photographer,
        description: this.description,
        publishedDate: this.publishedDate,
        modifiedDate: this.modifiedDate,
      };
      const videoSettings = {
        autoplay: JSON.parse(this.autoplay),
        autoplayMobile: JSON.parse(this.autoplayMobile),
        autoplaySubscriber: JSON.parse(this.autoplaySubscriber),
        sportsOverride: JSON.parse(this.sportsOverride),
        float: JSON.parse(this.float),
        floatHomepage: JSON.parse(this.floatHomepage),
        floatHomepageMobile: JSON.parse(this.floatHomepageMobile),
        floatHomepageSubscriber: JSON.parse(this.floatHomepageSubscriber),
        upNext: JSON.parse(this.upNext),
        isTaboolaMarkup: JSON.parse(this.isTaboolaMarkup),
        sportsCoincidence: JSON.parse(this.sportsCoincidence),
        stnKill: JSON.parse(this.stnKill),
        stnDisableSticky: JSON.parse(this.stnDisableSticky),
      };
      const sectionConfig = {
        lang: this.lang,
        dateFormat: this.dateFormat,
        pubTimeZone: this.pubTimeZone,
        isPaywall5050Service: JSON.parse(this.isPaywall5050Service),
      };
      this.videoStore.loadState(sectionConfig, videoData, videoCms, videoSettings);
    }
  }
</script>
