import CONFIG_ENUMS from './connatix.video.configEnums';

const  BASE_SETTINGS = (isvertical_video) => Object.freeze({
  customization: {
    responsive: true,
    ratioWidth:  isvertical_video ? 9 : 16,
    ratioHeight: isvertical_video ? 16 : 9,
  },
  rendering: {
    containerSelector: '.wps-player-container',
    insertPosition: CONFIG_ENUMS.InsertPositionEnum.WithinContainer,
    position: 1,
  },
});

export default BASE_SETTINGS;