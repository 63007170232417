export const relatedStoriesStore = (initialState = {}) => ({
  state: () => ({
    status: '',
    related: initialState,
  }),
  actions: {
    updateRelatedStories(payload) {
      this.related = payload.relatedStories;
    },
  },
  getters: {
    getRelated: state => state.related,
  },
});

export default { relatedStoriesStore };
