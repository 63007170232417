
import path from 'ramda/src/path';
import emptyVideoDetail from '../../video/services/emptyVideoDetail.json';


const getApiEnvirontment = function () {
  const isLocalMode = window.location.href.includes('//localhost');
  if (isLocalMode) return `https://${window.pageInfo.domainName}`;
  return '';
};

const getVideoDetailEndPoint = function (contentID) {
  return `${getApiEnvirontment()}/webapi-public/v2/content/${contentID}`;
};

const getTrendingEndPoint = function (publication = 'kansascity') {
  return `${getApiEnvirontment()}/webapi-public/v2/publications/${publication}/trending/videos`;
};
const getRelatedEndPoint = function (publication, brithcoveId) {
  return `${getApiEnvirontment()}/webapi-public/v2/publications/${publication}/related/${brithcoveId}`;
};

export default class OldClientRequest {
  // Profile request
  static buildProfilePath(id, page) {
    return `${getApiEnvirontment()}/webapi-public/content/${id}/usages/${page}`;
  }
  static getLatest(authorId, page = 1) {
    return fetch(this.buildProfilePath(authorId, page)).then(res => res.json());
  }
  // Search request
  static buildSearchPath(query, pubName) {
    return `${getApiEnvirontment()}/webapi-public/v2/publications/${pubName}/search`;
  }
  //WPS-4988 - converting get to post request changes
  static getSearch(term, page, orderBy, pubName) {
    const buildqueryparams = { 'q':term }
    if (orderBy !== '' && orderBy !== 'relevance') {
      buildqueryparams.sort = orderBy;
    }
    if (page > 1) {
      buildqueryparams.page = page;
    }
    
    const response = fetch(this.buildSearchPath(buildqueryparams, pubName), {
      method: 'POST',
      headers: {
        'X-Forwarded-Host': `${getApiEnvirontment()}`
      },
      body: JSON.stringify(buildqueryparams),
    }).then(res => res.json());
  
    return response;
  }
  // Topics request
  static BuildTagsPath(tag, page, pub) {
    const pubQuery = pub !== '' ? `?published_to=${pub}` : '';
    return `${getApiEnvirontment()}/webapi-public/v2/tags/${tag}/${page}${pubQuery}`;
  }

  static async getTagsContent(tag, page = 1, pub = '') {
    return (await fetch(this.BuildTagsPath(tag, page, pub))).json();
  }
 
  static async getVideoDetailContent(contentID) {
    return fetch(getVideoDetailEndPoint(contentID))
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error('Can not get video info');
      })
      .then(video => Object.assign(video, { description: path([0, 'text'], video.leadtext), web_url: video.url }))
      .catch(() => emptyVideoDetail);
  }
  static async getTrendingVideos(publication) {
    return fetch(getTrendingEndPoint(publication))
      .then(res => (res.ok) ? res.json() : { trending: [] }); // eslint-disable-line
  }

  static async getRelatedVideos(publication, brithcoveId) {
    return fetch(getRelatedEndPoint(publication, brithcoveId))
      .then(res => (res.ok) ? res.json() : { trending: [] }); // eslint-disable-line
  }
  // Section request
  static BuildSectionPath(sectionId, limit, offset) {
    const finalOffset = (offset) ? `&offset=${offset}` : '';
    return `${getApiEnvirontment()}/webapi-public/v2/sections/${sectionId}/content?limit=${limit}${finalOffset}`;
  }

  static async getSectionContent(sectionId, limit, offset) {
    return fetch(this.BuildSectionPath(sectionId, limit, offset))
      .then((res) => { if (res.ok) return res.json(); throw new Error(); });
  }
}
