<template>
  <section hidden>
    <div class="section-title">
      <div>
        <h5> {{ $t('video.trending').toUpperCase() }} </h5>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    
  }
</script>