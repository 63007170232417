<template>
  <div  ref="overlay-screen"
        class='wps-sharing-overlay-screen'>
    <div  @click="$emit('toogleSharingState')"
          class="wps-close-sharing-button">
      <span class="glyphicon glyphicon-remove"></span>
    </div>
    <div class="wps-sharing-overlay-icons">
      <!-- WPS-4919 - Added click event for sharing icons -->
      <a  :href="facebookURL"
          target="blank"
          class="wps-sharing-overlay-icons-item facebook-share glyphicon glyphicon-facebook"
          aria-label="Share on Facebook"
          @click="sharingIconsClicked">
      </a>
      <a  :href="twitterURL"
          target="blank"
          class="wps-sharing-overlay-icons-item twitter-share glyphicon glyphicon-twitter"
          aria-label="Share on Twitter"
          @click="sharingIconsClicked">
      </a>
      <a  :href="emailURL"
          target="blank"
          class="wps-sharing-overlay-icons-item email-share glyphicon glyphicon-envelope"
          aria-label="Share by Email"
          @click="sharingIconsClicked">
      </a>
      <a  @click="toogleEmbedCode"
          class="wps-sharing-overlay-icons-item glyphicon glyphicon-code vjs-overlay-sharing__embed"
          aria-label="Show embed code">
      </a>

      <form @click="shareForm"
            class="wps-sharing-form">
        <textarea :value="embedSrc"
                  v-show="embedCodeStatus"
                  class="wps-embed-code"
                  cols="30"
                  aria-label="Video embed code">
        </textarea>
        <div  class="wps-sharing-link-wrapper"
              v-if="!embedCodeStatus">
          <input  :value="forceHttps"
                  type="text"
                  class="wps-sharing-link"
                  aria-label="Video share link"
                  readonly>
        </div> 
        <button v-if="!embedCodeStatus"
                class="wps-copy-button"
                type="button">
                {{ $t('video.copy').toUpperCase() }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
  // @ts-nocheck
  import videoUtils from '../../../utils/video-utils';
  import { useVideoStore } from '../../store/video-component/video-store';
  import { mapStores } from 'pinia';

  export default {
    name: 'Sharing Component',
    data() {
      return {
        embedCodeStatus: false,
      }
    },
    props: {
      sharingState: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      ...mapStores(useVideoStore),
      facebookURL() {
        return `https://www.facebook.com/sharer/sharer.php?u=${this.videoStore.getUrl}`
      },
      twitterURL() {
        const title = encodeURIComponent(this.videoStore.getTitle);
        return `https://twitter.com/intent/tweet?url=${this.videoStore.getUrl}&text=${title}`
      },
      emailURL() {
        const title = encodeURIComponent(this.videoStore.getTitle);
        return `mailto:?subject=${title}&body=${this.videoStore.getUrl}`
      },
      forceHttps(){
        // @ts-ignore
        return this.$vueFilters.forceHttps(this.videoStore.getUrl);
      },
      embedSrc() {
        return [
          '<style>.mcclatchy-embed{position:relative;padding:40px 0 56.25%;height:0;overflow:hidden;max-width:100%}.mcclatchy-embed iframe{position:absolute;top:0;left:0;width:100%;height:100%}</style>',
          '<div class="mcclatchy-embed"><iframe src="',
          this.videoStore.getUrl,
          '/video-embed" width="640" height="400" frameborder="0" allowfullscreen="true"></iframe></div>',
        ].join('');
      },
    },
    methods: {
      toogleEmbedCode() {
        this.embedCodeStatus = !this.embedCodeStatus;
      },
      shareForm(event) {
        if (event.target.matches('button')) {
          const copyButton = event.target;
          const copyTextarea = Array
            .from(copyButton.parentElement.children)
            .filter(x => x !== copyButton && x.matches('.wps-sharing-link-wrapper'));
          copyTextarea[0].children[0].select();
          try {
            document.execCommand('copy');
            copyButton.innerHTML = this.$i18n.t('video.copied').toUpperCase();
            setTimeout(function () {
              copyButton.innerHTML = this.$i18n.t('video.copy').toUpperCase();
            }.bind(this), 2000);
          } catch (err) {
            return err;
          }
        }
      }, 
      //WPS-4919 - Emit the event to trigger in the player-component
      sharingIconsClicked(){
        this.$emit('sharingIconClick');
      },
    },
    updated() {
      const overlayScreen = this.$refs['overlay-screen'];
      if (this.sharingState) {
        overlayScreen.style.visibility = "visible";
      } else {
        overlayScreen.style.visibility = "hidden";
      }
    },
    
  }
</script>