/* eslint-disable max-len, no-underscore-dangle, no-param-reassign */
const firstLabel = 'background:#0F4491 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff';
const secondLabel = 'background:#373737 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff';

const isDev = (location.href.includes('//localhost') || location.href.includes('//qa1') || location.href.includes('//qa2')); // eslint-disable-line

const consoleLog = function (label, ...args) {
  if (isDev) {
    const finalArgs = ['%c MCC-VIDEO %c ' + label + ' ', firstLabel, secondLabel, args];
    console.log.apply(null, finalArgs);
  } else {
    console.log.apply(null, args);
  }
};

const devLog = (title, message) => { 
  if (isDev) consoleLog('VIDEO_PLAYER', title, message);
};

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const getAttributionTemplate = (byline, email, emailSubject) => `${email ? '<a href="mailto:' + email + emailSubject + '">' : ''}${byline}${email ? '<span class="glyphicon glyphicon-envelope"></span></a>' : ''}`;

// TODO: Ask for email field form web api and custom field on Connatix
const getAttribution = (title, photographer, email = null) => {
  const byline = photographer ? photographer.split(',') : [];
  const bylineEmail = email ? email.split(',') : [];

  const byline1 = byline[0] ? byline[0] : null;
  const byline2 = byline[1] ? byline[1] : null;
  const byline3 = byline[2] ? byline[2] : null;
  const email1 = bylineEmail[0] && validateEmail(bylineEmail[0]) ? bylineEmail[0] : null;
  const email2 = bylineEmail[1] && validateEmail(bylineEmail[1]) ? bylineEmail[1] : null;
  const email3 = bylineEmail[2] && validateEmail(bylineEmail[2]) ? bylineEmail[2] : null;
  const emailSubject = `?subject=${title}`;

  let attribution = getAttributionTemplate(byline1, email1, emailSubject);
  if (byline2) { attribution += `| ${getAttributionTemplate(byline2, email2, emailSubject)}`; }
  if (byline3) { attribution += `| ${getAttributionTemplate(byline3, email3, emailSubject)}`; }
  return attribution !== 'null' ? attribution : '';
};


const videoUtils = {
  isDev,
  consoleLog,
  devLog,
  getAttribution,
};

export default videoUtils;

