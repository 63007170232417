<!-- <template>
  <div :id="'sticky-player-'+videoStore.getInstanceId" class="wps-sticky-player">
    <div class="sticky-player-wrapper">
      <div :id="'sticky-player-video-'+videoStore.getInstanceId" class="sticky-player-video"></div>
      
      <div class="sticky-player-info">
        <h4>{{ videoStore.getTitle }}</h4>
        <figcaption class="sticky-player-description">
          {{ videoStore.getDescription }}
        </figcaption>
        <p class="byline" v-if="!!attribution">
          {{ $t('video.attribution.by') }}
          {{ attribution }}
          &nbsp;
        </p>
      </div>

      <div class="sticky-player-next-wrap" v-if="canUpNext" @click="videoStore.getNextVideoObj?.NextVideoClick()">
        <span class="up-next-title">Up Next</span>
        
        <div class="sticky-player-next-inner-wrap" 
          :style="{'background-image':`url(${videoStore.getNextVideoObj?.thumbnail})`}">
          <p class="up-next-description">{{ videoStore.getNextVideoObj?.title }}</p>
        </div>
      </div>

      <button type="button" class="close dismiss-sticky-player" aria-label="Close" tabindex="-1" @click="$emit('dismissSticky')">
        <span aria-hidden="true">
          &times;
        </span>
      </button>
    </div>
  </div>
</template> -->

<script>
  import videoUtils from '../../../utils/video-utils';
  import { useVideoStore } from '../../store/video-component/video-store';
  import { mapStores } from 'pinia';

  export default {
    computed: {
      ...mapStores(useVideoStore),
      attribution() {
        return videoUtils.getAttribution(
          this.videoStore.getTitle,
          this.videoStore.getPhotographer
        );
      },
      canUpNext() {
        return this.videoStore.getUpNext;
      },
    },
  }
</script>