// @ts-nocheck
import { createApp } from 'vue';
import { createPinia } from 'pinia';

import VideoComponent from './app/video-component.vue';

import { createGlobalVideoMap } from './global/global-video-map';
import { registerFiltersTo } from '../app/utils/VueFilters';
import i18nServices from '../app/translations/vuei18nPlugin';
import VideoPlayer from './provider/connatix.video';

 

const insertConnatixHeadScript = (cid, pid) => {
const videoInstances = document.querySelectorAll('.videoInstance');
const findvideoInst = videoInstances[0].querySelector('video');
const cms = {
    cid: findvideoInst.dataset.cid,
    instanceId: findvideoInst.dataset.instanceId,
    renderId: findvideoInst.dataset.renderId,
    playerId: JSON.parse(findvideoInst.dataset.playerId),
    videoId: findvideoInst.dataset.videoId,
    videoListIds: findvideoInst.dataset.videoListIds,
  };  
const data = {
    escenicId: findvideoInst.dataset.escenicId,
    assetType: findvideoInst.dataset.assetType,
    videoType: findvideoInst.dataset.videoType,
  };  
const settings = {
  };

  //creating a new ConnatixVideo instance with the provided parameters
const videoPlayerInstance = new VideoPlayer(cms, data, settings); 
  
  // Convert cid to string if it's not already
  const connatixCid = typeof cid === 'string' ? cid : cid.toString();
  let connatixPlayerId;

  switch (data.assetType) {
    case 'video': {     
      if (data.videoType !== 'video-gallery') {
        if(data.videoType === 'lead-asset' || data.videoType === 'inline'){
          if(videoPlayerInstance.isSubscriber()){              
            connatixPlayerId = typeof pid["regularSub-"+data.videoType] !== 'undefined' ? pid["regularSub-"+data.videoType] : pid.regular;
          } 
          else{            
            connatixPlayerId = typeof pid["regularNonsub-"+data.videoType] !== 'undefined' ? pid["regularNonsub-"+data.videoType] : pid.regular;
          } 
        }
        else{
          connatixPlayerId = pid.regular;
        }
      } 
      
      else {
        connatixPlayerId = pid.gallery;
      }
      break;
    }
    case 'playlist': {
      connatixPlayerId = pid.regular;
      break;
    }
    default: {
      videoPlayerInstance.renderFail('Asset type is not defined');
      break;
    }
  }

  // FE-2, Insert Connatix head script dynamically in the <head> section
  const connatixHeadScript = document.createElement('script');
  
  //FE-382 Connatix script updated with cnxel and appended with playerId
  connatixHeadScript.innerHTML = `!function(n){if(!window.cnxel){window.cnxel={},window.cnxel.cmd=[];var t=n.createElement('iframe');t.src='javascript:false'; t.display='none',t.onload=function(){var n=t.contentWindow.document,c=n.createElement('script');c.src='//cd.thecontentserver.com/player.js?cid=${connatixCid}&pid=${connatixPlayerId}',c.setAttribute('async','1'),c.setAttribute('type','text/javascript'),n.body.appendChild(c)},n.head.appendChild(t)}}(document);`;
  document.head.appendChild(connatixHeadScript);
};

// Function to check if an element is in the viewport
const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const videoInstances = document.querySelectorAll('.videoInstance');

// FE-2, find Cid for head
const findCid = videoInstances[0] && videoInstances[0].querySelector('video');
const oneCid = findCid && findCid.dataset.cid;

//FE-382 find Pid for head
const onePid = findCid && JSON.parse(findCid.dataset.playerId);

// FE-2, Insert Connatix head script before rendering logic
if (oneCid) insertConnatixHeadScript(oneCid, onePid);

const setUpInstances = () => {
  createGlobalVideoMap();

  // Intersection Observer configuration
  const observerOptions = {
    root: null, // Use the viewport as the root
    rootMargin: '0px',
    threshold: 0.5, // Trigger when 50% of the element is visible
  };

  // Intersection Observer callback
  const intersectionCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Load and mount the Vue app for the video instance
        const videoInstance = entry.target;
        const videoTagElement = videoInstance.querySelector('video');
        const videoDataset = videoTagElement.dataset;

        const VideoVueApp = createApp(VideoComponent, {
          ...videoDataset,
        });

        // Pinia's stores root
        VideoVueApp.use(createPinia());

        // Use Vue Filters
        registerFiltersTo(VideoVueApp);

        // Use i18n Translations
        const i18nService = i18nServices[videoDataset.lang];
        VideoVueApp.use(i18nService);

        // Mouting Application
        VideoVueApp.mount(videoInstance);

        // Stop observing the element once it's in the viewport
        observer.unobserve(videoInstance);
      }
    });
  };

  // Create Intersection Observer
  const observer = new IntersectionObserver(intersectionCallback, observerOptions);

  // Start observing each video instance
  videoInstances.forEach((videoInstance) => {
    if (isInViewport(videoInstance)) {
      // If the video instance is already in the viewport, load and mount it immediately
      const videoTagElement = videoInstance.querySelector('video');
      const videoDataset = videoTagElement.dataset;

      const VideoVueApp = createApp(VideoComponent, {
        ...videoDataset,
      });

      // Pinia's stores root
      VideoVueApp.use(createPinia());

      // Use Vue Filters
      registerFiltersTo(VideoVueApp);

      // Use i18n Translations
      const i18nService = i18nServices[videoDataset.lang];
      VideoVueApp.use(i18nService);

      // Mouting Application
      VideoVueApp.mount(videoInstance);
    } else {
      // If not in the viewport, start observing the element
      observer.observe(videoInstance);
    }
  });
};

if (videoInstances) {
  setUpInstances();
}
