<template>
    <figcaption>
      <span
        v-if="!!this.videoStore.getDescription">
        {{ this.videoStore.getDescription + ' ' }}
      </span>
      <span
        v-if="!!this.videoStore.getPhotographer"
        class="byline">
          {{ $t('video.attribution.by') }}
          {{ getAttribution() }}
      </span>
    </figcaption>
</template>
<script>
  import videoUtils from '../../../utils/video-utils';
  import { useVideoStore } from '../../store/video-component/video-store';
  import { mapStores } from 'pinia';

  export default {
    name: 'Description Component',
    computed: {
      ...mapStores(useVideoStore)
    },
    methods: {
      getAttribution() {
        return videoUtils.getAttribution(
          this.videoStore.getTitle,
          this.videoStore.getPhotographer
        );
      },
    }
  }
</script>