// @ts-nocheck
/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';

const useVideoStore = defineStore('video', {
  state: () => ({
    videoCms: {},
    videoData: {},
    videoSettings: {},
    sectionConfig: {},
    videoCurrentPosition: 0,
  }),
  actions: {
    loadState(sectionConfig, videoData, videoCms, videoSettings) {
      this.videoCms = { ...videoCms };
      this.videoData = { ...videoData };
      this.videoSettings = { ...videoSettings };
      this.sectionConfig = { ...sectionConfig };
    },
    loadVideoPlayerIds(instanceId) {
      if (Object.hasOwn(this.videoCms, 'instanceId') && Object.hasOwn(this.videoCms, 'renderId')) {
        this.videoCms.instanceId = instanceId;
        this.videoCms.renderId = `player-${instanceId}`;
      }
    },
    updateTitle(title) {
      this.videoData.title = title;
    },
    updateDescription(description) {
      this.videoData.description = description;
    },
    updatePhotographer(photographer) {
      this.videoData.photographer = photographer;
    },
    setVideoCurrentPosition(position) {
      this.videoCurrentPosition = position;
    },
    setNextVideoObj(obj) {
      this.videoData.nextVideoObj = obj;
    },
  },
  getters: {
    getInstanceId: state => state.videoCms.instanceId,
    getRenderId: state => state.videoCms.renderId,

    getAssetType: state => state.videoData.assetType,
    getVideoType: state => state.videoData.videoType,
    getEscenicId: state => state.videoData.escenicId,
    getUrl: state => state.videoData.url,
    getTitle: state => state.videoData.title,
    getChannel: state => (state.videoData.channel !== '' ? state.videoData.channel : state.videoData.sectionName),
    getSectionName: state => state.videoData.sectionName,
    getSectionUrl: state => state.videoData.sectionUrl,
    getPhotographer: state => state.videoData.photographer,
    getDescription: state => state.videoData.description,
    getPublishedDate: state => state.videoData.publishedDate,
    getModifiedDate: state => state.videoData.modifiedDate,
    getUpNext: state => state.videoSettings.upNext,
    getFloat: state => state.videoSettings.float,
    getIsTaboolaMarkup: state => state.videoSettings.isTaboolaMarkup,
    getNextVideoObj: state => state.videoData.nextVideoObj,

    getLang: state => state.sectionConfig.lang,
    getDateFormat: state => state.sectionConfig.dateFormat,
    getPubTimeZone: state => state.sectionConfig.pubTimeZone,
    getIsPaywall5050Service: state => state.sectionConfig.isPaywall5050Service,
    getVideoCurrentPosition: state => state.videoCurrentPosition,
  },
});

export { useVideoStore };
