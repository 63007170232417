<template>
  <div class="card read-next">
    <div class="video-detail">
      <player></player>
    </div>

    <div class="package">
      <header class="header">
        <a class="kicker h6" 
          :title="videoStore.getSectionName" 
          :alt="videoStore.getChannel"
          :href="videoStore.getSectionUrl" > 
          {{ videoStore.getSectionName }}
        </a>

        <h3 class="h1">{{ videoStore.getTitle }}</h3>
        <div class="bio">
          <div>
            <p class="byline" v-if="!!attribution">
              {{ $t('video.attribution.by') }}
              {{ attribution }}
              &nbsp;
            </p>
            <video-timestamp 
              :publishedDate="videoStore.getPublishedDate"
              :modifiedDate="videoStore.getModifiedDate"
              :isVideoCard="true"
            />
          </div>
        </div>
      </header> 

      <p>{{ videoStore.getDescription }}</p>
    </div>
  </div>
</template>

<script>
  import videoUtils from '../../../utils/video-utils';
  import { useVideoStore } from '../../store/video-component/video-store';
  import { mapStores } from 'pinia';

  import PlayerComponent from '../player-component/player-component.vue';
  import VideoTimestampComponent from './video-timestamp-component.vue';

  export default {
    components: {
      player: PlayerComponent,
      videoTimestamp: VideoTimestampComponent,
    },
    computed: {
      ...mapStores(useVideoStore),
      attribution() {
        return videoUtils.getAttribution(
          this.videoStore.getTitle,
          this.videoStore.getPhotographer
        );
      },
    },
  }
</script>