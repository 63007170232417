<template>
  <article class="card">
    <div class="package">
      <a 
        :href="urlSection(story.url)" 
        class="kicker h6" 
        :title="story.section" 
        :alt="story.section" 
        v-html="story.section">
      </a>
      <h3 class="h4">
      
        <a :href="story.url">{{ story.title }}</a>
      </h3>
      <video-timestamp 
        :publishedDate="story.published_date"
        :modifiedDate="story.modified_date"
        :isVideoCard="false"
      />
    </div>
  </article>
</template>

<script>
  import VideoTimestampComponent from './video-timestamp-component.vue';

  export default {
    props: {
      story: Object,
    },
    components: {
      videoTimestamp: VideoTimestampComponent,
    },
   
    methods: {
      urlSection(storyUrl){
        return this.$vueFilters.urlSection(storyUrl);
      },
    }
  }
</script>