<template>
  <time :class="{ 'update-date': wasModified() }" class="time">
    {{ getLocaleTimestamp() }}
  </time>
</template>

<script>
  import pick from "ramda/src/pick";
  import { useVideoStore } from '../../store/video-component/video-store';
  import { mapStores } from "pinia";

  export default {
    name: "TimestampComponent",
    props: {
      publishedDate: [Number, String],
      modifiedDate: [Number, String],
      isVideoCard: Boolean,
    },
    computed: {
      ...mapStores(useVideoStore),
    },
    methods: {
      locale() {
        return {
          lang: this.videoStore.getLang,
          pubTimeZone: this.videoStore.getPubTimeZone
        };
      },
      wasModified() {
        return this.$vueFilters.dateWasModified(this.publishedDate ,this.modifiedDate);
      },
      getLocaleTimestamp() {
        if(this.isVideoCard){
          // WPS-4980-- Show the published date for the video card
          return this.$vueFilters.localizeDate(this.publishedDate , this.locale);
        } else {
          return this.wasModified() ? `${this.$t('timestamp.updated')} ${this.$vueFilters.localizeDate(this.modifiedDate, this.locale)}` : this.$vueFilters.localizeDate(this.publishedDate , this.locale);
        }
      },
    },
  };
</script>