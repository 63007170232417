<template>
  <section v-if="relatedStories.length">
    <div class="section-title">
      <div>
        <h5> {{ $t('video.related').toUpperCase() }} </h5>
      </div>
    </div>

    <div class="flex-columns">
      <related-story-card v-for="(story,index) in relatedStories" 
        :key="index" 
        :story="story">     
      </related-story-card>
    </div>
  </section>
</template>

<script>
  import { useRelatedStoriesStore } from '../../store/video-detail/stores-creation';
  import { mapStores } from 'pinia';

  import RelatedStoryCardComponent from './related-story-card-component.vue';

  export default {
    components: {
      relatedStoryCard: RelatedStoryCardComponent,
    },
    computed: {
      ...mapStores(useRelatedStoriesStore),
      relatedStories() {
        const related = this.relatedStoriesStore.getRelated;
        if (related.length) {
          const relatedStories = this.filterStories(related);
          return (relatedStories.length > 3)? relatedStories.slice(0,3) : relatedStories;
        }
        return related;
      },
    },
    methods: {
      filterStories(related) {
        return related.filter(item => item.asset_type === 'story');
      },
    },
    created() {
      const rawRelated = this.relatedStoriesStore.getRelated;
      if(rawRelated.length) {
        const relatedStories = rawRelated.map(item => {
          const newItem = item;
          newItem.section = newItem.url.split('/')[3];
          return newItem;
        })
        const payload = {
          relatedStories,
        };
        this.relatedStoriesStore.updateRelatedStories(payload);
      }
    }
  }
</script>