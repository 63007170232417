import { defineStore } from 'pinia';
import { relatedStoriesStore } from './related-stories-store';
import { trendingVideosStore } from './trending-videos-store';
import { buildInitialState } from '../../../utils/pre-populated';

const initialState = buildInitialState();

export const useRelatedStoriesStore = defineStore(
  'relatedStories',
  relatedStoriesStore(initialState.relatedStories),
);

export const useTrendingVideosStore = defineStore(
  'trendingVideos',
  trendingVideosStore(initialState.trendingVideos),
);

export default {
  useRelatedStoriesStore,
  useTrendingVideosStore,
};

