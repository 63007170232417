const CONFIG_ENUMS_DEFAULT = {
  PlaybackModeEnum: {
    AutoPlay: 0,
    ScrollToPlay: 1,
    ClickToPlay: 2,
  },
  DefaultSoundModeEnum: {
    On: 1,
    Off: 0,
    OnHover: 2,
  },
  InsertPositionEnum: {
    WithinContainer: 0,
    BeforeContainer: 1,
    AfterContainer: 2,
    ReplaceContainer: 3,
    HideContainer: 4,
  },
  CloseButtonModeEnum: {
    Off: 0,
    On: 1,
    OnAfterFirstAd: 2,
    OnHover: 1,
    OnHoverAfterFirstAd: 2,
    AlwaysOn: 3,
  },
  QualityEnum: {
    144: 'Low144p',
    240: 'Low240p',
    360: 'Medium360p',
    480: 'Medium480p',
    720: 'High720p',
    1080: 'High1080p',
    Low144p: 144,
    Low240p: 240,
    Medium360p: 360,
    Medium480p: 480,
    High720p: 720,
    High1080p: 1080,
  },
  LineItemExcludedSlotsEnum: {
    PreRoll: 0,
    MidRoll: 1,
    PostRoll: 2,
  },
  ErrorTypesEnum: {
    SetupError: 'SetupError',
    NoPlayer: 'NoPlayer',
  },
  FloatingModeEnum: {
    Off: 0,
    OnPageLoad: 1,
    AfterInView: 2,
    Always: 3,
  },
  FloatingDevicesEnum: {
    Desktop: 0,
    Smartphone: 1,
    Tablet: 2,
    Other: 3,
  },
  FloatingFixedPositionModeEnum: {
    Page: 0,
    Element: 1,
  },
  FloatingScrollPositionEnum: {
    BottomRight: 0,
    BottomLeft: 1,
    TopRight: 2,
    TopLeft: 3,
    PinnedTop: 4,
    PinnedBottom: 5,
  },
  FloatingStatesEnum: {
    Float: 'float',
    Embed: 'embed',
  },
  FloatingAppendLocationEnum: {
    Above: 0,
    Below: 1,
    Within: 2,
  },
  ImplementationTypeEnum: {
    Amp: 1,
    Web: 0,
  },
  NextVideoModeEnum: {
    Next: 1,
    Loop: 0,
    Stop: 2,
  },
  LogoPositionEnum: {
    None: 0,
    ControlBar: 1,
    BottomRight: 2,
    BottomLeft: 3,
    TopRight: 4,
    TopLeft: 5,
  },
  PlayerTypesEnum: {
    InStream: 0,
    OutStream: 1,
    InStreamInApp: 0,
    Live: 3,
  },
  GalleryStyleModeEnum: {
    None: 0,
    Detailed: 1,
    Visual: 2,
  },
  GalleryOrientationEnum: {
    Landscape: 0,
    Portrait: 1,
  },
  InitialRenderingEnum: {
    ExpandWithAd: 0,
    FullyRendered: 1,
  },
  EndAdBreakPolicyEnum: {
    Hide: 0,
    HouseScreen: 1,
    Loop: 2,
  },
};

const CONFIG_ENUMS = globalThis?.cnxel?.configEnums ? globalThis.cnxel.configEnums : CONFIG_ENUMS_DEFAULT;

export default Object.freeze(CONFIG_ENUMS);
