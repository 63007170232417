<template>
  <sticky-player v-if="canFloat() && videoStore.getFloat" 
       @dismiss-sticky="closeStickyPlayer"></sticky-player>
  <div :id="'player-wrap-'+instanceId" class="wps-player-wrap">
    <div :id="'player-container-'+instanceId" class="wps-player-container" >
      <div :id="'player-'+instanceId" class="wps-player"></div>
      <sharing-component :sharingState="sharingState" @toogleSharingState="toogleSharingState" @sharingIconClick="sharingIconClick"></sharing-component>
    </div>
  </div>
  <description-component v-if="canShowDescription()"></description-component>
</template>

<script>
  // @ts-nocheck
  import videoUtils from '../../../utils/video-utils';
  import videoAllowed from '../../../settings/allowed-video-types';
  import { useVideoStore } from '../../store/video-component/video-store';
  import { mapStores } from 'pinia';

  import VideoPlayer from '../../../provider/connatix.video'
  import StickyPlayerComponent from './sticky-player-component.vue';
  import SharingComponent from './sharing-component.vue';
  import DescriptionComponent from './description-component.vue';

  export default {
    name: 'Player Component',
    components: {
      stickyPlayer: StickyPlayerComponent,
      descriptionComponent: DescriptionComponent,
      sharingComponent: SharingComponent,
    },
    data() {
      return {
        instanceId: '',
        videoPlayer: undefined,
        sharingState: false,
        playingState: false,
        modalState:false,
      };
    },
    computed: {
      ...mapStores(useVideoStore),
    },
    methods: {
      setVideoPlayerIds(videoId) {
        const theTime = (new Date()).getTime();
        this.instanceId = `${videoId}-${theTime}`;
        this.videoStore.loadVideoPlayerIds(this.instanceId);
      },
      taboolaMarkup(){
        if(this.videoStore.getIsTaboolaMarkup){
          document.querySelector(`#player-wrap-${this.instanceId}`).setAttribute('data-tb-thumbnail', '');
        }
      },
      canFloat(){
        return videoAllowed('float', this.videoStore.getVideoType);
      },
      canShowDescription(){
        return videoAllowed('description', this.videoStore.getVideoType);
      },
      canShowShareButton(){
        return videoAllowed('shareButton', this.videoStore.getVideoType);
      },
      closeStickyPlayer(){
        try{
          this.videoPlayer.onCloseStickyPlayer();
        }catch(err) {
          if (videoUtils.isDev) {
            videoUtils.consoleLog('VIDEO_PLAYER_CNX', 'The video player instance is not defined.', err);
          }
        }
      },
      openFullscreen(element) {
        if (element.requestFullscreen){
          element.requestFullscreen();
        } else if (element.webkitRequestFullscreen){
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen){
          element.msRequestFullscreen();
        }
      },
      closeFullscreen() {
        if (document.exitFullscreen){
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen){
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen){
          document.msExitFullscreen();
        }
      },
      toogleSharingState(){
        const playerContainer = this.videoPlayer.getVideoPlayerContainer();
        const shareButtonOverlay = playerContainer.querySelector('.wps-share-button-overlay');
        const sharingOverlayScreen = playerContainer.querySelector('.wps-sharing-overlay-screen');
        const videoContainer = playerContainer.querySelector('.cnx-video-container');

        this.sharingState = !this.sharingState;
        if(!this.sharingState){
          //* Returns the Original Playing State
          if(this.playingState !== this.videoPlayer.getPlayingState()){
            this.videoPlayer.playerApi.play();
          }
          shareButtonOverlay.style.visibility = 'visible';
          if (document.fullscreenElement) {
            this.closeFullscreen();
            this.openFullscreen(videoContainer);
          }
        }else{
          //* Saves the Playing State before opening the overlay
          this.playingState = this.videoPlayer.getPlayingState();
          if(this.playingState){
            this.videoPlayer.playerApi.pause();
          }
          shareButtonOverlay.style.visibility = 'hidden';
          if (document.fullscreenElement) {
            this.openFullscreen(sharingOverlayScreen);
          }
        }
      },
    
      updatePlayerOverlay(videoPlayer){
        const playerContainer = videoPlayer.getVideoPlayerContainer();
        const playerHeader = playerContainer.querySelector('.cnx-ui-head-buttons');
        if(playerHeader) {
          playerHeader.classList.add('wps-overlay-container');
          if(playerHeader.querySelector('.wps-title-overlay')){
            if(!!this.videoPlayer?.videoDetails?.title){
              this.videoStore.updateTitle(this.videoPlayer.videoDetails.title);
              playerHeader.querySelector('.wps-title-overlay').innerHTML = this.videoStore.getTitle;
            }
            if(!!this.videoPlayer?.videoDetails?.description){
              this.videoStore.updateDescription(this.videoPlayer.videoDetails.description);
            }
            if(!!this.videoPlayer?.videoDetails?.customFields){
              const byLineString =  this.videoPlayer.videoDetails.customFields.filter((element) => /byline/.test(element.name))
                                    .map((element) => element.value)
                                    .join();
              this.videoStore.updatePhotographer(byLineString);
            }
          }
          else {
            const videoTitleOverlay = document.createElement('div');
            if(this.videoStore.getVideoType === 'video-gallery') this.videoStore.updateTitle(this.videoPlayer.videoDetails.title);
            videoTitleOverlay.innerHTML = this.videoStore.getTitle;
            videoTitleOverlay.setAttribute('class', 'wps-title-overlay');
            playerHeader.insertAdjacentElement('afterbegin', videoTitleOverlay);

            if(this.canShowShareButton()){
              const shareButtonOverlay = document.createElement('div');
              shareButtonOverlay.innerHTML = '<span class="glyphicon glyphicon-share"></span>';
              shareButtonOverlay.setAttribute('class', 'wps-share-button-overlay');
              playerHeader.insertAdjacentElement('beforeend', shareButtonOverlay);

              shareButtonOverlay.addEventListener('mousedown',function(event){
                event.preventDefault();
                event.stopImmediatePropagation();
                event.stopPropagation();
                this.toogleSharingState();
              }.bind(this));
            }
          }
        }
      },
      renderVideoPlayer(){
        this.videoPlayer.render(this.videoPlayer)
          .then(() => {
            this.taboolaMarkup();
            this.updatePlayerOverlay(this.videoPlayer);
            //TODO: Temporary Action
            //TODO: Repeating this method is necessary to overwrite the title coming from CUE for the Connatix's title in the videoGallery.
            
            const playerContainer = this.videoPlayer.getVideoPlayerContainer();
            playerContainer.addEventListener("updateStoreEvent", () => {
              this.updatePlayerOverlay(this.videoPlayer);
            });
          });
      },
      //WPS-4919 -- Toggle the fullscreen to close it
      sharingIconClick(){
        this.videoPlayer.playerApi.toggleFullscreen(false);
      }
    },
    beforeMount() {
      this.setVideoPlayerIds(this.videoStore.getEscenicId);
    },
    mounted(){
      // @ts-ignore
      if(!!this.videoStore?.videoCms?.videoId || this.videoStore.getVideoType === 'video-gallery'){
        const videoPlayer = new VideoPlayer(
          this.videoStore?.videoCms,
          this.videoStore?.videoData,
          this.videoStore?.videoSettings
        );
        globalThis.wpsVideoPlayers.set(this.instanceId, videoPlayer);
        
        this.videoPlayer = videoPlayer;
        
        //Observe the modal state for immersive video -WPS 4869
        if(this.videoStore.getVideoType === 'immersive'){
          let element = document.getElementById("immersiveModal-"+this.videoStore.getEscenicId);
        
          this.observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
              if (mutation) {
                this.modalState = element.style.display === 'block';
              }
            });
          });
          this.observer.observe(element, {attributes: true});

        }else{
          this.renderVideoPlayer();
        }

      }
      else{
        if (videoUtils.isDev) {
          videoUtils.consoleLog('VIDEO_PLAYER_CNX', 'Fail', `Missing videoCMSID on ${this.videoStore.getInstanceId}`);
        }
      }
    },
    beforeDestroy() {
      const playerContainer = this.videoPlayer.getVideoPlayerContainer();
      const shareButtonOverlay = playerContainer.querySelector('.wps-share-button-overlay');
      shareButtonOverlay.removeEventListener('click', this.toogleSharingState);
    },
    watch:{
      modalState(value){
        if(value){
          //Fetch the last played video position stored in store and resume the video
          var videoCurrentPosition = this.videoStore.getVideoCurrentPosition;
          if(videoCurrentPosition){
            this.videoPlayer?.playerApi?.setVideoPosition(videoCurrentPosition);
          }else{
            //Render the video player once the modal is active in DOM
            this.renderVideoPlayer();
          }
          
          
        }else{
          //Store the video position
          var videoCurrentPosition = this.videoPlayer?.playerApi?.getVideoCurrentPosition();
          if(videoCurrentPosition){
            this.videoStore.setVideoCurrentPosition(videoCurrentPosition)
          }
          //Stop the video player once the modal closes
          this.videoPlayer.playerApi.pause();
        }
      }
    }
    
    
  }
</script>
